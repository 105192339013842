import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';
import { routesData } from '../models/pages-data';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnChanges {

    private subs = new SubSink();

    show: boolean = true;

    loading = false;
    user: any;
    routesData;
    isHome: boolean = false;
    currentRoute: string;

    constructor(private authenticationService: AuthenticationService,
        private router: Router) {
        this.routesData = routesData;
        this.currentRoute = "";
        this.subs.add(this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                // Hide progress spinner or progress bar
                this.currentRoute = event.url;
                (this.currentRoute === '/home' || this.currentRoute === '/') ? this.isHome = true : this.isHome = false;
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit() {
        this.loading = true;
        // this.user = this.authenticationService.tokenValue;
        this.subs.add(this.authenticationService.currentUser$.subscribe(x => {
            this.user = x;
        }));

        this.loading = false;
    }

    hideSidenav() {
        this.show = !this.show;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        window.location.reload();
        // this.cd.markForCheck();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

}

