import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeAll, map, toArray } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SessionMeasurementService {

    constructor(private http: HttpClient) { }

    getSiloSessions$(sessionId: number): Observable<any> {
        return this.http.get<any[]>(`${environment.api2Url}/oye/silo/${sessionId}/sessioni/`).pipe(
            mergeAll(),
            map((session: any) => {
                return {
                    id: session.id,
                    timestamp: this.toTimestamp(session.timestamp)
                }
            }),
            toArray()
        );
    }

    private toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum;
    }
}
