import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from "../../components/chart/chart.component";
import { Silo3dModule } from "../../components/silo3d/silo3d.module";
import { ChartModule } from "angular-highcharts";
import { SessionsService } from '../sessions.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@NgModule({
    declarations: [
        ChartComponent
    ],
    imports: [
        CommonModule,
        Silo3dModule,
        ChartModule,
        ReactiveFormsModule,
        MatChipsModule
    ],
    exports: [
        ChartComponent
    ],
    providers: [
        SessionsService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class SessionModule {
}
