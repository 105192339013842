import { GridComponent } from './../components/grid/grid.component';
import { Sessione } from 'src/app/core/clientV2/model/sessione';
import { Silo3dComponent } from './../components/silo3d/silo3d.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, tap, switchMap, catchError, EMPTY, retry } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EmptySiloComponent } from '../empty-silo/empty-silo.component';
import { ChangeDensityComponent } from '../change-density/change-density.component';
import _ from 'lodash';
import { SessionsService } from './sessions.service';
@Component({
    selector: 'app-sessions',
    templateUrl: './sessions.component.html',
    styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

    @ViewChild('silo3d') silo3d: Silo3dComponent;

    subs = new SubSink();

    sessionId: number;
    siloId: string;
    sessionsArray: Sessione[];
    model = undefined;

    tmpEmptyModel: any;// { faces: any; vertices: any; };
    noSession: boolean;
    sessionInfos: any;
    siloName: string;

    constructor(
        private sessions: SessionsService,
        private route: ActivatedRoute,
        public dialog: MatDialog
        ) { }

    openDialogSiloVuoto() {
        const dialogRef = this.dialog.open(GridComponent, {
            width: '85rem',
            height: '40rem',
            data: { siloId: this.siloId, registroAnnotazioni: true}
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openDialogDensita() {
        const dialogRef = this.dialog.open(GridComponent, {
            width: '42rem',
            height: '30rem',
            data: { siloId: this.siloId, registroAnnotazioni: false }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    ngOnInit(): void {
        this.subs.add(

            this.route.params.subscribe(params => {
                this.siloName = params['siloName'];
                this.siloId = params['siloId'];
            }),


            this.sessions.getSessionInfos$(this.siloId).pipe(
                tap(sessions => {
                    if (sessions.length < 1 || _.isEmpty(sessions)) {
                        this.noSession = true
                    }
                    this.sessionsArray = sessions;
                }),
                switchMap((val: any) => {
                    if(!val[0].idSessione) {
                        return EMPTY
                    }
                    return forkJoin([this.sessions.getSessionPoints$(val[0].idSessione),this.sessions.getEmptySessionPoints$(this.siloId)]).pipe(
                        tap(res => {
                            if(!res[0].faces && !res[0].vertices) {
                            } else if(!res[1].faces && !res[1].vertices) {
                            }
                            this.model = res;
                            this.tmpEmptyModel = res[1];
                        })
                    )
                }),
                retry(2),
                catchError(err => {
                    console.warn(err);
                    return EMPTY
                })
            ).subscribe(),

            // forkJoin([this.sessions.getSessionPoints$(this.siloId), this.sessions.getEmptySessionPoints$(this.siloId)]).pipe(
            //     tap(res => {
            //         if(!res[0].faces && !res[0].vertices) {
            //             this.isDisabled = true;
            //         } else if(!res[1].faces && !res[1].vertices) {
            //             this.isDisabled = true;
            //         }
            //         this.model = res;
            //         this.tmpEmptyModel = res[1];
            //     })
            // ).subscribe()
        );
    }

    setNewModel($event): void   {
        this.sessions.getSessionPoints$($event.id).subscribe(newValue => {
            this.model = [newValue, this.tmpEmptyModel];
        });
    }



    ngOnDestroy() {
        this.subs.unsubscribe();
    }

}
