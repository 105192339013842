<!-- <div *ngIf="true"> -->
<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button (click)="hideSidenav()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <h1>VET</h1>
    <span class="spacer"></span>
    <div class="logout">
      <!-- <a class="logout_name"> Hello {{ user.business_name }}</a> -->
      <a class="logout_name"> Hello {{ user?.first_name }} {{ user?.last_name }} </a>
      <button mat-icon-button (click)="logout()">Logout <mat-icon class="logout_icon">exit_to_app</mat-icon></button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="!isHome" class="second-toolbar" [ngClass]="{'second-toolbar_wide': !this.show}">

    <button mat-icon-button backButton class="second-toolbar_back-btn">
        <mat-icon>arrow_back_ios</mat-icon>Indietro
    </button>
    
    <app-ng-dynamic-breadcrumb class="second-toolbar_breadcrumb" [bgColor]="'transparent'" [fontSize]="'15px'" [fontColor]="'white'" [lastLinkColor]="'white'" [symbol]="' > '"></app-ng-dynamic-breadcrumb>

</mat-toolbar-row>
</mat-toolbar>

<mat-drawer-container [ngClass]="{'mat-drawer-container-home' : isHome}">
  <mat-drawer mode="side" opened *ngIf="show">
    <div>
      <mat-nav-list *ngFor="let route of routesData">
        <a mat-list-item [routerLink]="route.route">
          <mat-icon>{{route.icon}}</mat-icon> {{route.title}}
        </a>
      </mat-nav-list>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
    <app-loader></app-loader>

  </mat-drawer-content>
</mat-drawer-container>
<!-- </div> -->