import { AgGridModule, AgGridAngular } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { tokenInterceptor } from './helpers/token.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { HomeComponent } from './home/home.component';
import { ShellComponent } from './shell/shell.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './UI/material.module';
import { SitesComponent } from './sites/sites.component';
import { BackButtonDirective } from './helpers/back-button.directive';
import { SiloComponent } from './components/silo/silo.component';
import { MapComponent } from './components/map/map.component';
import { BASE_PATH } from 'src/app/core/clientV2';
import { InstallationComponent } from './components/installation/installation.component';
import { SessionsComponent } from './sessions/sessions.component';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { EmptySiloComponent } from './empty-silo/empty-silo.component';
import { ChangeDensityComponent } from './change-density/change-density.component';
import { ToastrModule } from 'ngx-toastr';
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { environment } from 'src/environments/environment';
import { Silo3dModule } from "./components/silo3d/silo3d.module";
import { SessionModule } from "./sessions/session/session.module";
import { SessionMeasurementComponent } from './components/session-measurement/session-measurement.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderInterceptor } from './services/loader-interceptor.service';
import { LoaderService } from './services/loader.service';
import { GridComponent } from './components/grid/grid.component';
import { CommonModule } from '@angular/common';
import { GridDateComponent } from './components/grid/custom-datepicker/custom-datepicker.component';
import { CustomDatepickerRendererComponent } from './components/grid/custom-datepicker-renderer/custom-datepicker-renderer.component';
import { SiloVuotoRendererComponent } from './components/grid/silo-vuoto-renderer/silo-vuoto-renderer.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        ShellComponent,
        SitesComponent,
        SiloComponent,
        MapComponent,
        InstallationComponent,
        SessionsComponent,
        EmptySiloComponent,
        ChangeDensityComponent,
        BackButtonDirective,
        SessionMeasurementComponent,
        LoaderComponent,
        GridComponent,
        GridDateComponent,
        CustomDatepickerRendererComponent,
        SiloVuotoRendererComponent
    ],
    imports: [
        BrowserModule,
        SessionModule,
        Silo3dModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        ToastrModule.forRoot(),
        NgDynamicBreadcrumbModule,
        // NoopAnimationsModule,
        CommonModule,
        AgGridModule,
    ],
    providers: [
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: tokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: BASE_PATH, useValue: environment.apiUrl },
        // { provide: BASE_PATH2, useValue: environment.api2Url },
        { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
