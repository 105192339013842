import { data } from './data';
import { map, Observable, tap, toArray, BehaviorSubject } from 'rxjs';
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, Input, Inject } from '@angular/core';
import { CellClickedEvent, CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, ValueGetterFunc, ValueGetterParams, ValueParserParams, ValueSetterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { GridDateComponent } from './custom-datepicker/custom-datepicker.component';
import { CustomDatepickerRendererComponent } from './custom-datepicker-renderer/custom-datepicker-renderer.component';
import { SiloVuotoRendererComponent } from './silo-vuoto-renderer/silo-vuoto-renderer.component';
import { CellOperationsService } from './cell-operations.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

const greyCellClassRules = {
    "cell-pass": params => !params.data.silo_vuoto,
    "cell-fail": params => params.data.silo_vuoto
};

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class GridComponent implements OnInit {

    @Input() siloId: number | string;
    @Input() registroAnnotazioni: boolean;

    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(private cellServices: CellOperationsService,
            @Inject(MAT_DIALOG_DATA) public data,
            public dialogRef: MatDialogRef<GridComponent>
        ) {}

    ngOnInit(): void {

    }

    public columnDefsRegistro: ColDef[] = [
        { field: 'id', width: 70 },
        { headerName: 'Descrizione', field: 'descrizione_contenuto', editable: true, wrapText: true },
        {
            headerName: 'Silo Vuoto',
            field: 'silo_vuoto',
            editable: false,
            width: 120,
            cellRenderer: 'siloVuotoRenderer',
            valueSetter: params => {
                params.data.silo_vuoto = params.newValue;
                return true;
            }
        },
        {
            headerName: 'Data Valida',
            field: 'startDateValidator',
            width: 130,
            cellRenderer: this.validationStatusRenderer,
            valueGetter: this.validationStatusRenderer
        },
        { headerName: 'Inizio', field: 'tempo_inizio', editable: true, cellEditor: 'agDateInput', cellRenderer: 'agDateRenderer', },
        { headerName: 'Fine', field: 'tempo_fine', editable: true, cellEditor: 'agDateInput', cellRenderer: 'agDateRenderer' },
        {
            field: 'densita',
            width: 100,
            editable: (params) => { return this.canCellBeEdited(params) },
            cellClassRules: greyCellClassRules,
            valueSetter: (params: ValueSetterParams) => {  //to make sure user entered number only
                var newValInt = parseInt(params.newValue);
                var valueChanged = params.data.densita !== newValInt;
                if (valueChanged) {
                    params.data.densita = newValInt ? newValInt : params.oldValue;

                }

                return valueChanged;
            },
        },
        {
            headerName: 'Messaggi',
        },
        // {
        //     field:'save',
        //     cellRenderer: this.saveRenderer,
        //     cellRendererParams: {
        //         isChanged: false
        //     },
        // }
    ];

    public columnDefsAllarmi: ColDef[] = [
        { field: 'messaggio', width: 390},
        { headerName: 'Data', field: 'timestamp', cellRenderer: 'agDateRenderer'},
    ]

    numberParser(params: ValueParserParams) {
        return Boolean(params.newValue);
    }

    public defaultColDef: ColDef = {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: true,
    };

    public allarmiColDef: ColDef = {
        resizable: true,
        editable: false,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: true,
    };

    public rowData$;

    public components = {
        'agDateInput': GridDateComponent,
        'agDateRenderer': CustomDatepickerRendererComponent,
        'startDateValidation': this.validationStatusRenderer,
        'siloVuotoRenderer': SiloVuotoRendererComponent
    };

    /**
     * All events must be registered here, after the grid has been initialized
     */
    onGridReady(params: GridReadyEvent) {
        if (this.data.registroAnnotazioni) {
            this.rowData$ = this.cellServices.getRegistroAnnotazioniData$(this.data.siloId ?? 1);
        }

        if (!this.data.registroAnnotazioni) {
            this.rowData$ = this.cellServices.getAllarmiData$(this.data.siloId ?? 1);
        }

        // this.cellServices.prime(data, false)
    }

    onCellClicked(e: CellClickedEvent): void {
        // console.log('cellClicked', e);
        //localStorage.setItem('current', JSON.stringify(e.data))
    }

    /**
     * Cells operations
     */
    deleteRow(): void {
        this.cellServices.onDeleteRow(this.agGrid);
    }

    addRow(): void {
        this.cellServices.onAddRow(this.agGrid, this.data.siloId, true);
    }

    close(): void {
        this.dialogRef.close()
    }

    /**
     * END: Cells operations
     */


    //------------------------------------------------------------------------------
    // HELPERS

    onCellValueChanged(event: CellValueChangedEvent): void {
        // get selected cell id
        const id = event.node.id
        // get all the node obj
        const node = this.agGrid.api.getRowNode(id);
        // compare the selected cell values with the current changes
        const isEqual = _.isEqual(JSON.stringify(node.data), localStorage.getItem('current'));
        if (event.newValue != undefined && !isEqual) {
            // this.saveRenderer(true);
            this.cellServices.onSave(this.agGrid, false, node.data.id, node.data);
        }
    }

    private startDateSetter(params: ValueParserParams) {
        console.log(params)
        if (params && params.newValue > 0 && params.data.tempo_fine < params.newValue) {
            params.data[params.colDef.field] = params.newValue;
            return true;
        }
        return false;
    }


    /**
     * RENDERER functions
    */

    // startDateValidator renderer
    // validate the data: appears a tick if true, otherwise cross
    validationStatusRenderer(params) {
        let none = ``;
        let tick = `<span style="font-size: 30px; color:green">&#10003;</span>`;
        let cross = `<span style="font-size: 30px; color:red">&#x292B;</span>`;
        if (params?.data && params.data.tempo_fine > params.data.tempo_inizio) {
            return tick;
        }
        if (params?.data && params.data.tempo_fine < params.data.tempo_inizio  && params.data.tempo_fine < new Date().toISOString().slice(0, 19).replace('T', ' ')) {
            return cross;
        }
        return none
    }

    // saveRenderer(params) {
    //     let isChanged;
    //     const activeSave = `<span><i style="cursor: pointer" class="material-icons">save</i></span>`;
    //     const disabledSave = `<span><i style="opacity: 0.4;filter: alpha(opacity=40);" class="material-icons">save</i></span>`;

    //     if(isChanged || params) {
    //         return activeSave;
    //     }
    //     return disabledSave;

    // }

    /**
     * END: RENDERER functions
    */

    // disabe programmatically the density if silo_vuoto is true
    canCellBeEdited(params) {
        if (params.data.silo_vuoto) {
            return false;
        }
        return true;
    }
}


