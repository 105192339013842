<!-- <div *ngIf="sessionsArray;else noResults">
    <app-chart [data]="sessionsArray"></app-chart>
</div> -->

<!-- <ng-template #noResults>
    <div style="display: flex; align-items: center;">
        <h3>Qualcosa è andato storto, clicca nuovamente sulla sessione per caricare il modello.</h3>
    </div>
</ng-template> -->

<div>
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p><b>{{siloName}}</b></p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-chart fxFlex="1 1 auto" [data]="sessionsArray"></app-chart>
    </mat-expansion-panel>
<br>
    <mat-divider></mat-divider>
<br>
    <div class="footer">

        <div class="footer_sessioni">
            <app-session-measurement *ngIf="!noSession; else noSessionMeasurement" (getSessionId)="setNewModel($event)" [sessionId]="siloId" style="cursor: pointer;"></app-session-measurement>
        </div>

        <ng-template #noSessionMeasurement>
            <div class="footer_sessioni_no-data">
                <h3>C'è stato un errore o non ci sono sessioni.</h3>
            </div>
        </ng-template>
        <!-- <mat-chip-list class="mat-mdc-chip-set-stacked">
            <mat-chip color="primary">Sessione numero: {{ sessionInfos?.id}}</mat-chip>
            <mat-chip color="primary">Data sessione: {{ sessionInfos?.timestamp | date:'medium'}}</mat-chip>
        </mat-chip-list> -->
        <app-silo3d #silo3d *ngIf="model && !noSession" [model]="model"></app-silo3d>

        <div *ngIf="noSession" class="footer_silo3d-no-data">
            <h1>Non ci sono dati da mostrare!</h1>
            <img class="footer_silo3d-no-data_image" src="assets/wrong.png">
        </div>

        <div class="footer_buttons">
            <button class="footer_buttons_dialogButtons" mat-raised-button color="primary" (click)="openDialogSiloVuoto()">Registro annotazioni</button>
            <button class="footer_buttons_dialogButtons" mat-raised-button color="primary" (click)="openDialogDensita()">Allarmi</button>
        </div>

    </div>

</div>
