<div *ngIf="!currentUser">
  <mat-card>
    <mat-card-title>VET Login</mat-card-title>
      <img mat-card-image src="assets/logo-vet-srl.png" alt="Vet">
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <p>
          <mat-form-field>
            <input type="text" matInput placeholder="Username" formControlName="username">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password">
          </mat-form-field>
        </p>

        <p *ngIf="error" class="error">
          {{ error }}
        </p>

        <div class="button" style="justify-content: center">
          <button type="submit" mat-button>Login</button>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
