import { AfterViewInit, Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, OnDestroy, ElementRef } from '@angular/core';
import { OrbitControls } from '@three-ts/orbit-controls';
import * as THREE from 'three';
import _ from 'lodash';


@Component({
    selector: 'app-silo3d',
    templateUrl: './silo3d.component.html',
    styleUrls: ['./silo3d.component.scss']
})
export class Silo3dComponent implements OnChanges, AfterViewInit {

    @Input() model;

    @ViewChild('canvas', { static: true }) private canvasRef;

    noData: boolean = false;
    scene = new THREE.Scene();
    renderer: THREE.WebGLRenderer;
    camera: THREE.OrthographicCamera;
    axisHelper: THREE.AxesHelper;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        let dataModel = changes['model']?.currentValue;

        if (this.scene && (dataModel || (dataModel[0].faces && dataModel[0].vertices))) {
            this.init(dataModel[0] ?? dataModel, dataModel[1]);
        }

        if (!dataModel && !dataModel[0].vertices && !dataModel[0].faces && !dataModel[1] && !dataModel[1].vertices && !dataModel[1].faces){
            this.noData = true;
        }
    
    }

    ngAfterViewInit(): void {
        this.initScene();
    }

    private initScene() {
        const WIDTH = 700;
        const HEIGHT = 500;

        this.camera = new THREE.OrthographicCamera(WIDTH / -2, WIDTH / 2, HEIGHT / 2, HEIGHT / -2, 0.1, 20000);


        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(500, 500);

        this.canvasRef.nativeElement.append(this.renderer.domElement);

        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.zoomSpeed = 2;

        controls['position0'].set(0, 50, 12);
        controls['target0'].set(0, 0, 0);
        controls['zoom0'] = 18;

        this.axisHelper = new THREE.AxesHelper(5);
        this.scene.add(this.axisHelper);


        let component: Silo3dComponent = this;

        (function render() {
            requestAnimationFrame(render);
            component.renderer.setClearColor(0xffffff, 1);
            component.renderer.render(component.scene, component.camera);
        } ());

        controls.reset();

        // TODO: set the initial tilting
        // camera.rotation.z = TODO;

        //--------------------------------------        

        // this.luci(this.scene);
    }

    private luci(s) {
        // s: scena a cui aggiungere le luci
        // ========================  LUCI =================================

        var ambient = new THREE.AmbientLight(0x221100);
        s.add(ambient);

        var lights = [];
        var pointLightHelper = [];
        var sphereSize = 10;

        lights[0] = new THREE.PointLight(0xffffff, 0.8, 0);
        lights[1] = new THREE.PointLight(0xffffff, 0.8, 0);
        lights[2] = new THREE.PointLight(0xffffff, 1, 0);

        lights[0].position.set(50, 50, 50);
        lights[1].position.set(0, -50, 30);
        lights[2].position.set(-60, 0, -10);

        var index;
        for (index = 0; index < lights.length; ++index) { //Per ogni luce
            //Aggiunge un Helper per individuare la collocazione della luce stessa
            pointLightHelper[index] = new THREE.PointLightHelper(lights[index], sphereSize);
            s.add(pointLightHelper[index]);
            s.add(lights[index]);    //Aggiunge la luce alla scena
        }
    }

    init(model, emptyModel?) {
        
        this.scene.clear();

        this.luci(this.scene);
        this.scene.add(this.axisHelper);
        // ===========================================================

        const emptyModelGeometry = new THREE.BufferGeometry();

        if (!_.isEmpty(emptyModel && emptyModel.vertices && emptyModel.faces)) {
            emptyModelGeometry.setIndex(emptyModel.faces);
            const emptyModelVertices = new Float32Array(emptyModel.vertices);
            emptyModelGeometry.setAttribute('position', new THREE.Float32BufferAttribute(emptyModelVertices, 3));

            emptyModelGeometry.computeVertexNormals();

            var emptyModelMaterial = new THREE.MeshLambertMaterial({
                color: new THREE.Color('grey'),
                side: THREE.DoubleSide,
                opacity: 0.5,
                transparent: true,
                depthWrite: false
            });

            const emptyModelObject = new THREE.Mesh(emptyModelGeometry, emptyModelMaterial);

            this.scene.add(emptyModelObject);
        }

        // ===========================================================
        const geometry = new THREE.BufferGeometry();

        geometry.setIndex(model.faces);
        const vertices = new Float32Array(model.vertices);
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

        geometry.computeVertexNormals();

        var material = new THREE.MeshLambertMaterial({
            color: new THREE.Color('red'),
            side: THREE.DoubleSide,
            opacity: 0.5,
            transparent: true,
            depthWrite: false
        });

        const modelObject = new THREE.Mesh(geometry, material);
        
        this.scene.add(modelObject);
    }

}
