import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { environment } from 'src/environments/environment';

@Injectable()
export class tokenInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authenticationService.tokenValue;
        const user = this.authenticationService.currentUserValue;

        //const isLoggedIn = token && user;
        const isLoggedIn = token;

        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    authorization: `Token ${token.token}`
                }
            });
        }

        return next.handle(request);
    }
}