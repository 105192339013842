<div *ngIf="installations$ | async">
    <mat-chip-list>
        <mat-chip color="primary">
            Impianti {{nomeInstallation}}
        </mat-chip>
    </mat-chip-list>
    <div *ngIf="isEmpty" class="isEmpty">
        <h1>Non ci sono impianti da mostrare <mat-icon>error_outline</mat-icon>
        </h1>
    </div>

    <div class="installation" *ngFor="let installation of installations$ | async">
        <mat-card class="installation_card" (click)="navigateToSites(installation.sito, installation.nome)"
            
            style="cursor: pointer;">
            <mat-card-title-group>
                <mat-card-title>{{installation.nome}}</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
                <img mat-card-md-image src="assets/installation.jpg">
            </mat-card-title-group>
            <mat-card-content *ngIf="installation.note; else noNote">
                {{installation.note}}
            </mat-card-content>
        </mat-card>

        <ng-template #noNote>
            Nessuna nota da mostrare
        </ng-template>

    </div>
</div>