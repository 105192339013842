export const routesData = [
    {
        icon: 'home',
        title: 'Home',
        tooltip: 'Home',
        route: 'home'
    },
    {
        icon: 'dashboard',
        title: 'Grid',
        tooltip: 'Grid',
        route: 'grid'
    }
]