<div class="grid-container-registro">
    <div class="grid-container-registro_operations">
        <button *ngIf="data.registroAnnotazioni" mat-raised-button (click)="addRow()" color="primary">Aggiungi</button>
        <button *ngIf="data.registroAnnotazioni" mat-raised-button (click)="deleteRow()" color="primary">Cancella</button>
        <span class="spacer"></span>
        <button mat-raised-button (click)="close()" color="warn">X</button>
    </div>
    <!-- AG Grid Angular Component -->
    <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-alpine" 
        [columnDefs]="data.registroAnnotazioni ? columnDefsRegistro : columnDefsAllarmi"
        [defaultColDef]="data.registroAnnotazioni ? defaultColDef : allarmiColDef" 
        [sideBar]="true"
        [rowData]="rowData$ | async" 
        [rowSelection]="'multiple'" 
        [animateRows]="true"
        (gridReady)="onGridReady($event)" 
        (cellClicked)="onCellClicked($event)"
        (cellValueChanged)="onCellValueChanged($event)" 
        [components]="components" 
        [pagination]="true"
        [paginationPageSize]="'30'"
        ></ag-grid-angular>
</div>