<div class="chart">
    <div class="content" *ngIf="data">
        <div class="bars">
            <span>{{data.pesokg / 100 | number: '1.1-2'}}q</span>
            <span class="chart_content_bars_percentage">{{this.percentage | number: '1.2-2'}}%<mat-icon *ngIf="data['cla_sonda']" class="text-yellow">warning</mat-icon></span>
            <div class="bar" [style.background-color]="data.color" [style.height.px]="size"></div>
        </div>
    </div>
    <div class="line"></div>
    <div class="legends">
        <span>
            <b>{{data?.name}}</b>
            {{description}}
            <!-- <p [ngStyle]="{'color': data?.stato ? 'green' : 'red'}">Stato: {{data?.stato ?  'Attivo' : 'Spento'}}</p> -->
        </span>
    </div>
</div>
