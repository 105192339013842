<div style="width: 115%;background-color: white;">
    <input style="height: 35px;border-right: transparent;border: 0px solid black" type="datetime-local"
        [(ngModel)]="selectedDate">
    <a style='position: absolute; 
            right: -35px;
            top: -1px;
            background-color: lightskyblue;
            padding: 5px;'
            title='clear' data-clear (click)="close()">
        <mat-icon>clear</mat-icon>
    </a>
</div>