import { environment } from './../../../environments/environment';
import { OyeService } from './../../core/clientV2/api/oye.service';
import { SubSink } from 'subsink';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeAll, Observable, retry, toArray } from 'rxjs';
import { Impianto } from '../../core/clientV2';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-installation',
    templateUrl: './installation.component.html',
    styleUrls: ['./installation.component.scss'],
})
export class InstallationComponent implements OnInit, OnChanges {

    installations$ = new Observable<Impianto[]>;
    idInstallation: any;
    nomeInstallation: any;
    isEmpty: boolean;
    private subs = new SubSink();
    httpClient: any;

    constructor(private route: ActivatedRoute, private installation: OyeService, private http: HttpClient, private navigation: Router) { }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnInit(): void {
        this.subs.add(
            this.route.params.subscribe(params => {
                this.idInstallation = params['idInstallation'];
                this.nomeInstallation = params['nomeInstallation'];
                this.installations$ = this.getInstallations$(this.idInstallation);
                this.subs.add(this.installations$.subscribe(empty => empty.length == 0 ? this.isEmpty = true : null));
            }));
    }

    getInstallations$(id) {
        return this.http.get<Impianto[]>(`${environment.api2Url}/oye/sito/${id}/impianti/`).pipe(
            mergeAll(),
            toArray(),
            retry(2)
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    navigateToSites(sito, nome) {
        this.navigation.navigate(['installation', this.idInstallation, this.nomeInstallation, 'sites', sito, nome])
    }

}
