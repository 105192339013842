import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, EMPTY, tap } from 'rxjs';
import { GetTokenService, UserDetailsService } from '../core/clientV2';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private tokenSubject: BehaviorSubject<any>;
    public token$: Observable<User>;

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser$: Observable<any>;
    private options;

    constructor(private http: HttpClient, private auth: GetTokenService, private user: UserDetailsService) {
        this.tokenSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('token')));
        this.token$ = this.tokenSubject.asObservable();

        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    public get tokenValue(): User {
        return this.tokenSubject.value;
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.api2Url}/get-token/`, {"username": username, "password": password}).pipe(
            tap((token) => {
                localStorage.setItem('token', JSON.stringify(token));
                this.tokenSubject.next(token);
            })
        )
    }

    getUserData() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.tokenValue.token}`
        });
        this.options = { headers: headers };

        return this.http.get<any>(`${environment.api2Url}/user-details/`, this.options).pipe(
            tap((userDetail:any) => {
                localStorage.setItem('currentUser', JSON.stringify(userDetail));
                this.currentUserSubject.next(userDetail)
            })
        );
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        this.tokenSubject.next(null);
    }
}