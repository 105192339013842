import { SessionMeasurementService } from './session-measurement.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Sessione } from 'src/app/core/clientV2/model/sessione';


@Component({
    selector: 'app-session-measurement',
    templateUrl: './session-measurement.component.html',
    styleUrls: ['./session-measurement.component.scss']
})
export class SessionMeasurementComponent implements OnInit {

    @Input() sessionId: number;
    @Output('getSessionId') getSessionId = new EventEmitter();

    sessions$: Observable<Sessione[]>;
    activeId: string | number;

    constructor(private measurementService: SessionMeasurementService) { }

    ngOnInit(): void {
        this.sessions$ = this.measurementService.getSiloSessions$(this.sessionId);
    }

    emitSession(session)   {
        this.activeId = session.id;
        this.getSessionId.emit(session);
    }
    

}
