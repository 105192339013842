import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, map, of, tap, switchMap, throwError, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CellOperationsService {

    constructor(private http: HttpClient, private toastr: ToastrService) { }

    onDeleteRow(agGrid): void {
        const ids = agGrid.api.getSelectedRows();
        console.log(ids);

        agGrid.api.applyTransaction({
            remove: ids
        })
        
        const idsToRemove = ids.map(rowItem => rowItem.id);
        console.log(idsToRemove)
        
        idsToRemove.forEach(id => {
            const url = `${environment.api2Url}/oye/registro/${encodeURIComponent(String(id))}/`
            this.http.delete(url).subscribe();
        });
    }

    onAddRow(agGrid, silo, isNew): void {

        const date = new Date();
        const startDate = date.setDate(date.getDate() - 1);
        const formattedstartDate = moment(startDate).toISOString();

        const item = {
            silo: silo,
            descrizione_contenuto: '',
            silo_vuoto: false,
            tempo_inizio: formattedstartDate,
            tempo_fine: new Date(),
            densita: 0
        }

        this.onSave(agGrid, isNew, null, item);
    }

    onSave(agGrid, isNew, id, data): void {
        const url = `${environment.api2Url}/oye/registro/`;

        const tempoInizio = moment(data.tempo_inizio);
        const tempoFine = moment(data.tempo_fine);
        const currentDate = moment(Date());

        if(tempoInizio.diff(tempoFine) > 0) {
            this.showError('Data d\'inizio deve essere minore della data d\'inizio');
        } else if(tempoFine.diff(currentDate) - 1000 >= 0) {
            this.showError('Data di fine deve essere minore della data attuale');
        } else {
            if(!isNew) {
                this.http.patch(url+`${encodeURIComponent(id)}/`, {
                    ...data
                }).pipe(
                    tap(() => this.showSuccess()),
                    catchError((e) =>{
                        console.error(e);
                        this.showError()
                        return EMPTY;
                    })
                ).subscribe();
            }

            if(isNew) {
                this.http.post(url, data, {responseType: 'json'}).pipe(
                    tap(res => {
                        agGrid.api.applyTransaction({
                            addIndex: 0,
                            add: [ res ]
                        });
                        this.showSuccess();
                    }),
                    catchError((e) =>{
                        console.error(e);
                        this.showError()
                        return EMPTY
                    })
                ).subscribe();
            }
        }
    }

    getRegistroAnnotazioniData$(siloId) {
        const url = `${environment.api2Url}/oye/silo/${encodeURIComponent(siloId)}/registro_annotazioni/`;
        return this.http.get(url);
    }

    getAllarmiData$(siloId) {
        const url = `${environment.api2Url}/oye/silo/${encodeURIComponent(siloId)}/allarmi/`;
        return this.http.get(url);
    }

    /**
     * 
     * utility function to prime data
     */
    prime(data, registro = true): void {
        let url = ''

        if(registro) {
            url = `${environment.api2Url}/oye/registro/`
            of(data).pipe(
                map((data) => {
                    return {
                        descrizione_contenuto: data.descrizione_contenuto, 
                        silo_vuoto: data.silo_vuoto,
                        tempo_inizio: data.tempo_inizio,
                        tempo_fine: data.tempo_fine,
                        densita: data.densita,
                        silo: data.silo
                    }
                }),
                switchMap(item => {
                    return this.http.post(url, item)
                })
            ).subscribe()
        }

        if(!registro)   {
            url = `${environment.api2Url}/oye/allarmi/`
            from(data).pipe(
                map((data:any) => {
                    return {
                        messaggio: data.descrizione_contenuto, 
                        timestamp: data.tempo_inizio,
                        silo: data.silo
                    }
                }),
                switchMap(item => {
                    return this.http.post(url, item)
                })
            ).subscribe()
        }
    }

    clearSelection(agGrid): void {
        agGrid.api.deselectAll();
    }


    /**
     * TOAST
     */

    showSuccess() {
        this.toastr.success('Salvataggio effettuato', 'Salvato', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
        });
    }

    showError(msg = '') {

        const message = msg;

        if(!!message) {
            return this.toastr.error(`${message}`, 'Non Salvato', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right'
            });    
        }

        return this.toastr.error('Salvataggio non riuscito!', 'Non Salvato', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right'
        });
    }
}
