<!-- <mat-card #card *ngFor="let session of (sessions$ | async)"
    class="measurement" [ngClass]="session.id == activeId ? 'active' : ''" (click)="emitSession(session)">
    <mat-card-header>
        <mat-card-title>
            <p>Sessione {{session.id}}</p>
        </mat-card-title>
        <mat-card-subtitle>
            <p>Data: {{session.timestamp | date: 'd/MM/yy, HH:mm'}}</p>
        </mat-card-subtitle>
    </mat-card-header>
</mat-card> -->

<mat-card *ngFor="let session of (sessions$ | async)" class="measurement" [ngClass]="session.id == activeId ? 'active' : ''">
    <div (click)="emitSession(session)">
        <p><b>Sessione: </b> {{session.id}}</p>
        <p>Data: {{session.timestamp | date: 'd/MM/yy, HH:mm'}}</p>
    </div>
</mat-card>