import {SessionsComponent} from './sessions/sessions.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {AuthGuard} from './helpers/auth.guard';
import {HomeComponent} from './home/home.component';
import {InstallationComponent} from './components/installation/installation.component';
import {LoginComponent} from './components/login/login.component';
import {SitesComponent} from './sites/sites.component';
import {updateLocale} from 'moment';
import { GridComponent } from './components/grid/grid.component';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [
                {
                    label: 'Home',
                    url: 'home',
                }]
        }
    },
    {
        path: 'grid',
        component: GridComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [
                {
                    label: 'Grid',
                    url: 'grid'
                }
            ]
        }
    },
    {
        path: 'installation/:idInstallation/:nomeInstallation',
        component: InstallationComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [
                {
                    label: 'Home',
                    url: 'home/',
                },
                {
                    label: `Impianto: {{nomeInstallation}}`,
                    url: 'installation/:idInstallation/:nomeInstallation/',
                },

            ]
        }
    },
    {
        path: 'installation/:idInstallation/:nomeInstallation/sites/:idSito/:nomeSito',
        component: SitesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [
                {
                    label: 'Home',
                    url: 'home/',
                },
                {
                    label: `Impianto: {{nomeInstallation}}`,
                    url: 'installation/:idInstallation/:nomeInstallation/',
                },
                {
                    label: 'Sito: {{nomeSito}}',
                    url: 'installation/:idInstallation/:nomeInstallation/sites/:idSito/:nomeSito',
                }
            ]
        }
    },
    {
        path: 'installation/:idInstallation/:nomeInstallation/sites/:idSito/:nomeSito/sessions/:siloId/:siloName',
        component: SessionsComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./sessions/session/session.module').then(m => m.SessionModule),
        data: {
            breadcrumb: [
                {
                    label: 'Home',
                    url: 'home/',
                },
                {
                    label: `Impianto: {{nomeInstallation}}`,
                    url: 'installation/:idInstallation/:nomeInstallation/',
                },
                {
                    label: 'Sito: {{nomeSito}}',
                    url: 'installation/:idInstallation/:nomeInstallation/sites/:idSito/:nomeSito',
                },
                {
                    label: 'Silo: {{siloName}}',
                    url: 'installation/:idInstallation/:nomeInstallation/sites/:idSito/:nomeSito/session/:siloId/:siloName',
                }
            ]
        }
    },
    {path: 'login', component: LoginComponent},
    {path: '**', redirectTo: 'login'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
