import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Silo3dComponent} from "./silo3d.component";


@NgModule({
    declarations: [
        Silo3dComponent
    ],
    exports: [
        Silo3dComponent
    ],
    imports: [
        CommonModule,
    ]
})
export class Silo3dModule {
}
