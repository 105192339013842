import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-datepicker-renderer',
  templateUrl: './custom-datepicker-renderer.component.html',
  styleUrls: ['./custom-datepicker-renderer.component.scss']
})
export class CustomDatepickerRendererComponent implements OnInit, ICellRendererAngularComp {
    formattedDate: any;

  constructor() { }
    agInit(params: ICellRendererParams<any, any>): void {
        this.formattedDate = params.value;
    }
    refresh(params: ICellRendererParams<any, any>): boolean {
        this.formattedDate = params.value;
        return true;
    }

  ngOnInit(): void {
  }

}
