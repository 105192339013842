<div class="home">
    <div class="home_sites">
        <div *ngFor="let sito of siti$ | async">
            <mat-card class="home_sites_card">
                <mat-card-title-group>
                    <mat-card-title (click)="navigateToInstallation(sito.id, sito.nome)" style="cursor: pointer;" >
                        <mat-icon>line_style</mat-icon> {{sito.nome}}
                    </mat-card-title>
                    <mat-card-subtitle>{{sito.ubicazione}}</mat-card-subtitle>
                </mat-card-title-group>
                <mat-card-content>
                    {{sito.latitudine}}
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <app-map [data]="mapData"></app-map>
</div>