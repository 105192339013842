<mat-chip-list>
    <mat-chip color="primary">
        Silo sede: {{nomeSito}}
    </mat-chip>
</mat-chip-list>

<div class="sites_container">
    <div *ngFor="let silo of silos$ | async">
        <app-silo style="cursor: pointer;" [data]="silo" 
        (click)="navigateToSites(silo.id, silo.name)"> <a></a></app-silo>
    </div>
</div>