import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Sito } from '../core/clientV2';
import { from, map, mergeMap, Observable, retry, toArray } from 'rxjs';
import { SitoService } from '../core/clientV2/api/sito.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading = false;
  users: User[];

  siti$ = new Observable<Sito[]>();
  mapData;

  constructor(private sitoService: SitoService, private router: Router,private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.siti$ = this.sitoService.sitoList();
    
    this.siti$.pipe(
      mergeMap(val => from(val).pipe(
        map(item => [item.ubicazione, item.longitudine, item.latitudine])
      )),
      toArray(),
      retry(2),
    ).subscribe(x => this.mapData = x);
  }

  navigateToInstallation(idSito, nomeSito)  {
    this.router.navigate(['installation', idSito, nomeSito]);
  }

}
