import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { StockChart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import { numberFormat } from 'highcharts/highcharts.src';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges {

    @Input() data: any;

    yAxisOptions: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] = [
        {
            title: {
                text: 'Peso stimato',
            }
        },
        {
            title: {
                textAlign: "center",
                align: "middle",
                text: 'Volume vuoto misurato',
            },
            opposite: false,
        },
    ]

    computedData = [];

    seriesOptions: any;
    stock: StockChart;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'].currentValue && this.stock) {
            this.initChart();
            changes['data'].currentValue.forEach(el => {
                this.computedData.unshift(
                    {
                        x: parseInt(el.timestamp),
                        y: el.peso_prodotto,
                        peso: el.peso_prodotto,
                        volume_prodotto: el.volume_prodotto,
                        idSessione: el.idSessione,
                        ordineGriglia: el.ordine_griglia
                    }
                );
            });
        }
    }

    ngOnInit(): void {
        this.initChart();
    }

    initChart(): void {
        this.stock = new StockChart({
            rangeSelector: {
                selected: 0
            },
            tooltip: {
                useHTML: true,

                formatter: function () {
                    var date = Highcharts.dateFormat('%a, %b %e, %Y %H:%M ', (<number>this.x));
                    this.points.forEach(function (point) {
                        return date += `
                        <br>Peso: ${numberFormat(point.point['peso'] / 100, 1.1)} q
                        <br>Volume: ${numberFormat(point.point['volume_prodotto'], 1.1)}
                        <br>IdSessione: ${point.point['idSessione']}
                        <br>Ordine griglia: ${point.point['ordineGriglia']}
                        <br>Rating misura: ${point.point['rating']}
                        </div>`;
                    })
                    return date;
                }
            },
            yAxis: this.yAxisOptions,
            series: [
                {
                    type: 'line',
                    data: this.computedData,
                }
            ]
        });
    }

}
