import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import { OSM } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/layer/Vector';
import ControlScaleLine from 'ol/control/ScaleLine';
import { fromLonLat, transform } from 'ol/proj';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import Marker from 'ol-marker-feature';
import Popup from 'ol-popup';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() data;

  public map!: Map;

  initState = {
    "lat": 42.296798979790196,
    "lon": 12.802209375657758,
    "bounds": [
      645193.0200995952,
      4296111.781685466,
      2120759.7391245943,
      6102928.172328321
    ],
    "projection": "EPSG:4326"
  }

  center = fromLonLat([this.initState.lon, this.initState.lat]);

  constructor() {
  }

  // example = [
  //   [16.832884810949587, 40.86015298016221],
  //   [13.721960817267975, 42.97343305225448]
  // ]

  ngAfterViewInit(): void {
    if (this.data) {
      this.data.forEach(coord => {
        this.addPopupText(coord);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['data']?.currentValue && this.map) {

      this.data.forEach(coord => {
        this.addPopupText(coord);
      });
    }

    if (!this.map) {
      this.initMap();
    }
  }

  ngOnInit(): void { }

  private initMap() {

    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }), //this.vectorLayer
      ],
      target: 'map',
      view: new View({
        center: transform(this.center, 'EPSG:4326', 'EPSG:4326'),
        zoom: 5.7, maxZoom: 9, minZoom: 5,
        extent: this.initState.bounds,
        constrainOnlyCenter: true,
      }),
    });
  }


  addPopupText(coords) {
    const marker = new Marker(fromLonLat([coords[1], coords[2]]));
    marker.set('info', coords[0], true);
    marker.setMap(this.map);

    const popup = new Popup({ offset: [15, -30] });
    this.map.addOverlay(popup);

    popup.show(marker.getLocation(), marker.get('info'));

    marker.addEventListener('click', function (evt) {
      // this.router.navi
    })
  }

  // addPinpoint(coords) {
  //   this.map.addLayer(new Vector({
  //     source: new VectorSource({
  //       features: [
  //         new Feature({
  //           geometry: new Point(fromLonLat(coords)),
  //         })
  //       ]
  //     })
  //   }))
  //   // this.addPopupText(coords);
  // }

  /** Utility function to get points on the map */
  show(event) {
    //   this.map.on('singleclick', function (evt) {
    //     // console.log(evt.coordinate);
    //     let toadd = [];
    //     toadd = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    //     // convert coordinate to EPSG-4326
    //     console.log(transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
    //   });
  }
}

