import { ICellEditorParams, IDateParams } from 'ag-grid-community';
import { Component, ViewChildren } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-grid-date',
    templateUrl: './custom-datepicker.component.html',
})
export class GridDateComponent implements ICellEditorAngularComp {

    selectedDate = '';
    date: any;
    params: ICellEditorParams<any, any>;

    formatted = (p) => {return moment(p).format("YYYY-MM-DD[T]HH:mm:ss")}

    agInit(params: ICellEditorParams<any, any>): void {
        this.params = params;
        this.selectedDate = this.formatted(params.value);
    }

    getValue() {
        return this.selectedDate;
    }

    isPopup?(): boolean {
        return true;
    }

    onDateChanged(selectedDates: any) {
        this.date = selectedDates[0] || null;
    }


    close() {
        this.params.api.stopEditing();
    }

}