import { BarChartModel } from './../../models/bar-chart.model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-silo',
    templateUrl: './silo.component.html',
    styleUrls: ['./silo.component.scss']
})
export class SiloComponent implements OnInit, OnChanges {


    @Input() data: BarChartModel;

    @Input() height: number = 160;

    public total = 0;
    public maxHeight = 160;
    size: number;
    percentage: number;
    description: any;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['data'].currentValue) {
            this.initChart();
        }
    }

    ngOnInit(): void {
        this.initChart();
    }

    initChart() {
        this.description = this.data['descrizione_contenuto'] ?? '-';

        this.percentage = this.data['riempimento_relativo']*100;
        this.size = this.maxHeight*this.data['riempimento_relativo'];
    }
}
