import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map, mergeAll, Observable, toArray } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Sessione } from '../core/clientV2';

@Injectable({
    providedIn: 'root'
})
export class SessionsService {

    constructor(private http: HttpClient) { }

    getSessionPoints$(siloId) {
        return this.http.get<any[]>(`${environment.api2Url}/oye/sessione/${siloId}/punti/`).pipe(
            map((points: any) => {
                return {
                    faces: points.mesh,
                    vertices: points.punti
                }
            }),
        );
    }

    getEmptySessionPoints$(siloId) {
        return this.http.get<any[]>(`${environment.api2Url}/oye/silo/${siloId}/punti_modello/`).pipe(
            map((points: any) => {
                return {
                    faces: points.mesh,
                    vertices: points.punti
                }
            }),
        );
    }

    getSessionInfos$(siloId): Observable<Sessione[]> {
        return this.http.get<any[]>(`${environment.api2Url}/oye/silo/${siloId}/sessioni/`).pipe(
            mergeAll(),
            // filter((silo: any) => silo.silo == siloId),
            map(siloSession => {
                const timestamp = this.toTimestamp(siloSession.timestamp)
                return {
                    idSessione: siloSession.id,
                    timestamp: timestamp.toString(),
                    volume_vuoto: siloSession.volume_vuoto,
                    volume_prodotto: siloSession.volume_prodotto,
                    peso_prodotto: siloSession.peso_prodotto,
                    ordine_griglia: siloSession.ordine_griglia,
                    silo: siloSession.silo,
                };
            }),
            toArray()
        )
    }

    private toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum;
    }


}
