import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading = new BehaviorSubject(false);

//   private loading: boolean = false;

  constructor() { }

  setLoading(loading: boolean) {
    this.isLoading.next(loading);
    // this.loading = loading;
  }

  getLoading(): boolean {
    return this.isLoading.value;
  }
}