import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-silo-vuoto-renderer',
    templateUrl: './silo-vuoto-renderer.component.html',
    styleUrls: ['./silo-vuoto-renderer.component.scss']
})
export class SiloVuotoRendererComponent implements ICellRendererAngularComp {

    gridOptions: GridOptions;
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams<any, any>): boolean {
        this.params.value = params.value;
        return true;
    }

    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }

}
