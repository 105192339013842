import { Silo } from './../core/clientV2/model/silo';
import { SubSink } from 'subsink';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SitesService } from './sites.service';

@Component({
    selector: 'app-sites',
    templateUrl: './sites.component.html',
    styleUrls: ['./sites.component.scss']
})

export class SitesComponent implements OnInit {

    idSito: any;
    silos: Silo[];
    nomeSito: string;
    private subs = new SubSink();
    silos$: Observable<any>;
    idInstallation: any;
    nomeInstallation: any;
    siloId: any;

    constructor(private route: ActivatedRoute, private navigation: Router, private sitesService: SitesService) { }

    ngOnInit(): void {
        this.subs.add(
            this.route.params.subscribe(params => {
            this.idInstallation = params['idInstallation'];
            this.nomeInstallation = params['nomeInstallation'];
            this.idSito = params['idSito'];
            this.nomeSito = params['nomeSito'];
        }));

        this.silos$ = this.sitesService.getSilosData$(this.idSito);

    }

    

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    navigateToSites(idSilo, nameSilo)   {
        // [routerLink]="['/installation', idInstallation, nomeInstallation, 'sites', idSito, nomeSito, 'sessions', silo.id, silo.name]"
        this.navigation.navigate(['installation', this.idInstallation, this.nomeInstallation, 'sites', this.idSito, this.nomeSito, 'sessions', idSilo, nameSilo ])
    }
}
